<template>
  <div class="confusion">
    <div class="title">
      <h2>Confusion</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form :inline="true" :model="confusionFiliter">
              <el-form-item label="AffiliateIds:" label-width="100px">
                <group-select
                  v-model="confusionFiliter.affiliateIds"
                  :data-source="affiliatesGroupList"
                  multiple
                  :loading="affiliateLoading"
                  clearable
                  reserve-keyword
                  filterable
                  hasAll
                  placeholder="请选择"
                />
              </el-form-item>
              <el-form-item label="SourceIds:" label-width="100px">
                <group-select
                  v-model="confusionFiliter.sourceIds"
                  :data-source="sourcesList"
                  multiple
                  :loading="sourceLoading"
                  clearable
                  reserve-keyword
                  filterable
                  hasAll
                  placeholder="请选择"
                />
              </el-form-item>
              <el-form-item label="OfferIds:" label-width="100px">
                <el-input v-model="confusionFiliter.offerIds"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="searchConfusion(1)">Search</el-button>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="addConfusionsClick">Add</el-button>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="delConfusions">Delete</el-button>
              </el-form-item>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <el-table
              :data="confusionsList"
              highlight-current-row
              @selection-change="handleSelectionChange"
              @selection-all="handleSelectionChange"
              border
              stripe
              style="width:100%;"
              height="80vmin"
            >
              <el-table-column type="selection" width="55" align="center"></el-table-column>
              <el-table-column label="AffiliateId" prop="affiliateId">
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="toAffiliateDetailClick(scope.row)">{{
                    scope.row.affiliateId
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="SourceId" prop="sourceId">
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="toSourceDetailClick(scope.row)">{{
                    scope.row.sourceId
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="OfferId" prop="offerId">
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="toOfferDetailClick(scope.row)">{{
                    scope.row.offerId
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Period" prop="period"></el-table-column>
              <el-table-column label="Clicks" prop="clicks"></el-table-column>
              <el-table-column label="Cv" prop="cv"></el-table-column>
              <el-table-column
                label="Description"
                prop="description"
                min-width="100"
              ></el-table-column>
              <el-table-column
                label="UpdateTime"
                prop="updateTime"
                min-width="100"
              ></el-table-column>
              <el-table-column label="UpdateBy" prop="updateBy" min-width="100"></el-table-column>
              <el-table-column label="Operation">
                <template slot-scope="scope">
                  <el-button type="primary" @click="updateClick(scope.row)">Update</el-button>
                </template>
              </el-table-column>
            </el-table>
            <pagination
              class="block pagePanel"
              :pageTotal="confusionsTotal"
              @handleSizeChange="handleconfusionsSizeChange"
              @handleCurrentChange="handleconfusionsCurrentChange"
              :pageSize="pageSize"
              :currentPage="currentPage"
            ></pagination>
          </el-card>

          <el-dialog
            :title="dialogType === 'new' ? 'Add' : 'Update'"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            :append-to-body="true"
          >
            <el-form :model="confusions" label-width="100px">
              <el-row>
                <el-col :lg="11" :md="20">
                  <el-form-item label="AffiliateId:">
                    <group-select
                      v-model="confusions.affiliateIds"
                      :data-source="affiliatesGroupList"
                      multiple
                      :loading="affiliateLoading"
                      clearable
                      reserve-keyword
                      filterable
                      placeholder="请选择"
                      :disabled="dialogType === 'edit'"
                    />
                  </el-form-item>
                </el-col>
                <el-col :lg="12" :md="20" :offset="1">
                  <el-form-item label="SourceId:">
                    <el-select
                      v-model="confusions.sourceIds"
                      reserve-keyword
                      multiple
                      :loading="sourceLoading"
                      clearable
                      filterable
                      placeholder="请选择"
                      style="width:100%;"
                      :disabled="dialogType === 'edit'"
                    >
                      <el-option-group
                        v-for="group in sourcesList"
                        :key="group.label"
                        :label="group.label"
                      >
                        <el-option
                          v-for="item in group.children"
                          :key="item.id"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-option-group>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :lg="11" :md="20">
                  <el-form-item label="OfferIds:">
                    <el-input
                      v-model="confusions.offerIds"
                      style="width:100%"
                      placeholder="write confusion OfferIds"
                      :disabled="dialogType === 'edit'"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :lg="12" :md="20" :offset="1">
                  <el-form-item label="Period:">
                    <el-input
                      v-model="confusions.period"
                      style="width:100%;"
                      placeholder="write confusion Period..."
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :lg="11" :md="20">
                  <el-form-item label="Clicks:">
                    <el-input
                      v-model="confusions.clicks"
                      style="width:100%"
                      placeholder="write confusion clicks..."
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :lg="12" :md="20" :offset="1">
                  <el-form-item label="Cv:">
                    <el-input
                      v-model="confusions.cv"
                      style="width:100%;"
                      placeholder="write confusion Cv..."
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :lg="11" :md="20">
                  <el-form-item label="Description:">
                    <el-input
                      style="width:100%;"
                      v-model="confusions.description"
                      placeholder="write confusion Description..."
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="addConfusions">确 定</el-button>
            </div>
          </el-dialog>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import { getConfusionList, addOrUpdateConfusion, deleteConfusion } from 'api/affiliate/confusion';
  import { cloneDeep } from 'lodash-es';
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';

  import { myMixin } from '@/mixins/mixins.js';
  import { mapState, mapActions } from 'vuex';
  let clickTimer = null;
  const defaultConfusion = {
    sourceIds: [],
    affiliateIds: [],
    offerIds: '',
    period: '',
    clicks: '',
    cv: '',
    description: '',
  };
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      GroupSelect,
    },
    data() {
      return {
        confusionFiliter: {
          sourceIds: null,
          affiliateIds: null,
        },
        confusions: Object.assign({}, defaultConfusion),
        confusionsList: [],
        confusionsTotal: null,
        confusionsSelectedList: [],
        dialogVisible: false,
        dialogType: 'new',
        pageSize: 10,
        currentPage: 1,
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
    },
    mounted() {
      this.getAffiliateList();
      this.getSourceIdsList();
      this.searchConfusion();
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      ...mapActions('source', ['getSourceIdsList']),
      toAffiliateDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        clickTimer = window.setTimeout(() => {
          this.$nextTick(() => {
            const { href } = this.$router.resolve({
              path: '/affiliate/detail',
              query: {
                affiliateId: row.affiliateId,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
      toSourceDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        clickTimer = window.setTimeout(() => {
          this.$nextTick(() => {
            const { href } = this.$router.resolve({
              path: '/source/detail',
              query: {
                sourceId: row.sourceId,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
      toOfferDetailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        clickTimer = window.setTimeout(() => {
          this.$nextTick(() => {
            const { href } = this.$router.resolve({
              path: '/offer/detail',
              query: {
                offerId: row.offerId,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
      searchConfusion(curPage) {
        if (curPage) {
          this.currentPage = curPage;
        }
        let param = {
          ...this.confusionFiliter,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (Array.isArray(param.sourceIds)) {
          param.sourceIds = param.sourceIds.join(',');
        }
        if (Array.isArray(param.affiliateIds)) {
          param.affiliateIds = param.affiliateIds.join(',');
        }

        getConfusionList(param).then((response) => {
          if (response.code === 200) {
            this.confusionsList = response.result;
            this.confusionsTotal = response.total;
          }
        });
      },
      handleconfusionsSizeChange(val) {
        this.pageSize = val;
        this.handleconfusionsCurrentChange(1);
      },
      handleconfusionsCurrentChange(val) {
        this.currentPage = val;
        this.searchConfusion();
      },
      addConfusionsClick() {
        this.confusions = Object.assign({}, defaultConfusion);
        this.dialogType = 'new';
        this.dialogVisible = true;
      },
      addConfusions() {
        const param = { ...this.confusions };
        console.log(this.confusions);
        param.offerIds = param.offerIds ? param.offerIds?.split(',') : [];

        addOrUpdateConfusion(param).then((response) => {
          if (response.code == 200) {
            if (this.dialogType === 'new') {
              this.$message({
                message: 'Add Success',
                type: 'success',
              });
            } else {
              this.$message({
                message: 'Update Success',
                type: 'success',
              });
            }
            this.searchConfusion();
          } else {
            this.$message.error('Error:' + response.message);
          }
        });
        this.dialogVisible = false;
        this.flag = false;
      },
      delConfusions() {
        const length = this.confusionsSelectedList.length;
        if (length <= 0) {
          this.$message.warning('请先选择要删除的数据~');
          return;
        }
        this.$confirm('Are you want to delete ?', 'Tip', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
        })
          .then(() => {
            const ids = this.confusionsSelectedList.map((item) => item.id)?.join();
            this.deleteConfusion(ids);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      updateClick(row) {
        this.confusions = cloneDeep(row);
        this.confusions.sourceIds = this.confusions.sourceId && [this.confusions.sourceId];
        this.confusions.affiliateIds = this.confusions.affiliateId && [this.confusions.affiliateId];
        this.dialogType = 'edit';
        this.dialogVisible = true;
      },
      deleteConfusion(ids) {
        deleteConfusion(ids).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Delete Success',
              type: 'success',
            });
            this.searchConfusion();
          } else {
            this.$message.error('Delete Error:' + response.message);
          }
        });
      },
      handleSelectionChange(val) {
        this.confusionsSelectedList = val;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
