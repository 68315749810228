import request from 'utils/request';

const api = {
  CONFUSION_LIST: '/api/affiliate/confusion/list',
  CONFUSION_ADD_OR_UPDATE: '/api/affiliate/confusion/addOrUpdate',
  CONFUSION_DELETE: '/api/affiliate/confusion/delete',
};
export function getConfusionList(query) {
  return request({
    url: api.CONFUSION_LIST,
    method: 'get',
    params: query,
  });
}
export function addOrUpdateConfusion(data) {
  return request({
    url: api.CONFUSION_ADD_OR_UPDATE,
    method: 'post',
    data,
  });
}
export function deleteConfusion(ids) {
  return request({
    url: api.CONFUSION_DELETE,
    method: 'delete',
    params: {
      ids,
    },
  });
}
